import { Link } from 'react-router-dom'

const Footer = () => (
  <footer className='px-8 xs:px-16 py-16'>
    <div className='container m-auto max-w-6xl py-6 flex'>
      <div className='flex flex-1 flex-wrap md:flex-row flex-row justify-between text-gray2'>
        
        <div className='w-full md:w-1/2 flex-shrink-0 pr-10'>
          <img alt='' src='images/holepunch-logo-short.svg?refresh=1' />
          <h4 className='my-3 text-2xl font-bold'>
            The Peer-to-Peer Company
          </h4>
          <p className='my-3 text-muted'>Create apps that don’t use any servers whatsoever. No arbitrary limits just better apps that can be shipped instantly, directly to your users.</p>
          <p className='text-muted text-xs'>Copyright © 2024-2025 Holepunch, S.A. de C.V. All rights reserved.</p>
        </div>

        <div className='w-1/2 md:w-1/5 flex-shrink-0'>
          <h5 className='mb-4 antialiased text-muted1 mt-10'>
            Social
          </h5>
          <ul className='flex flex-col space-y-4'>
            <li>
              <a
                title='Twitter'
                href='https://twitter.com/holepunch_to'
                target='_blank'
                rel='noreferrer'
                className='text-muted'
              >
                Twitter
              </a>
            </li>

            <li>
              <a
                title='Discord'
                href='https://discord.com/invite/holepunch'
                target='_blank'
                rel='noreferrer'
                className='text-muted'
              >
                Discord
              </a>
            </li>
          </ul>
        </div>

        <div className='w-1/2 md:w-1/5 flex-shrink-0'>
          <h5 className='mb-4 antialiased text-muted1 mt-10'>
            Company
          </h5>
          <ul className='flex flex-col space-y-4'>
            <li className='relative'>
              <Link
                title='Privacy Statement'
                to='/privacy'
                className='text-muted'
              >
                Privacy Statement
              </Link>
            </li>
            <li className='relative'>
              <Link
                title='Website Terms'
                to='/terms'
                className='text-muted'
              >
                Website Terms
              </Link>
            </li>
            <li className='relative'>
              <a
                title='Join the team'
                target='_blank'
                rel='noreferrer'
                href='https://holepunch.recruitee.com/'
                className='text-muted'
              >
                Join the Team
              </a>
            </li>
            <li>
              <a
                title='Get in touch'
                href='mailto:hello@holepunch.to'
                className='text-muted'
              >
                Get in Touch
              </a>
            </li>
          </ul>
        </div>

      </div>
    </div>

  </footer>
)

export default Footer
