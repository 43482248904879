import { useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'
import Layout from '../components/Layout'

const Terms = () => {

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [])

  return (
    <Layout>
      <section className='terms w-full px-6 sm:px-16  antialiased mb-20 text-sm'>
        <div className='content px-2 sm:px-16'>
          <Link to='/' className='flex hover:text-white'>
            <svg
              viewBox='0 0 33 22'
              xmlns='http://www.w3.org/2000/svg'
              className='w-4 mr-4 text-white'
              fill='currentColor'
              stroke='none'
            >
              <path d='M12 1.4375C12.3125 1.75 12.5 2.125 12.5 2.5C12.5 2.9375 12.3125 3.3125 12 3.5625L6.0625 9.5H31.5C32.3125 9.5 33 10.1875 33 11C33 11.875 32.3125 12.5 31.5 12.5H6.0625L12 18.5C12.625 19.0625 12.625 20 12 20.5625C11.4375 21.1875 10.5 21.1875 9.9375 20.5625L1.4375 12.0625C0.8125 11.5 0.8125 10.5625 1.4375 10L9.9375 1.5C10.5 0.875 11.4375 0.875 12 1.4375Z' />
            </svg>
            <span className='text-sm'>Back</span>
          </Link>

          <h1 className='text-2xl font-bold py-6'>HOLEPUNCH.TO WEBSITE TERMS</h1>

          <p className='font-bold'>
            PLEASE READ THESE TERMS CAREFULLY BEFORE YOU START TO USE THE SITE (EACH AS DEFINED BELOW). BY USING THE SITE, YOU ACKNOWLEDGE THAT YOU HAVE COMPLETELY READ, UNDERSTAND, AND AGREE TO BE BOUND AND ABIDE BY THESE TERMS. IF YOU DO NOT AGREE TO THESE TERMS, AS AMENDED OR MODIFIED BY ANY SUBSEQUENT AMENDMENT, CHANGE OR UPDATE, YOU MUST NOT ACCESS OR USE THE SITE.
          </p>

          <br />

          <h2 className='font-bold italic'>1. What’s in these terms?</h2>
          <br />
          <p>
            1.1. These terms and conditions (including any documents expressly incorporated by reference, these “<span className="font-bold">Terms</span>”) govern the use of our website, holepunch.to (together with any sub-domains of our website, unless expressly excluded by their own terms and conditions, the “<span className="font-bold">Site</span>”). Click on the links below to go straight to more information on each area:
          </p>
          <br />
          <ul className="list-disc pl-4">
            <li>Acceptance of these Terms</li>
            <li>We may make changes to these Terms</li>
            <li>Who are we?</li>
            <li>Who may use the Site?</li>
            <li>We may make changes to, suspend or withdraw the Site</li>
            <li>How do you contact us?</li>
            <li>How may you use our Site?</li>
            <li>How can you use the information on our Site?</li>
            <li>Can you use our intellectual property?</li>
            <li>Can you link to our Site?</li>
            <li>Beta or Pre-release Testing</li>
            <li>How may we use your feedback?</li>
            <li>How may we use your personal information?</li>
            <li>Our liability for the Site</li>
            <li>Governing law and other miscellaneous terms of the Site</li>
          </ul>
          <br />
          <h2 className='font-bold italic'>2. Acceptance of these Terms</h2>
          <br />
          <p>2.1. By using the Site, you acknowledge that you have completely read, understand, and agree to be bound and abide by these Terms. If you do not agree to these Terms, as amended or modified by any subsequent amendment, change or update, you must not access or use the Site.</p>
          <br />
          <p>2.2. These Terms constitute a contract between Holepunch Inc. (together with any assigns or successor operators of the Site, “<span className="font-bold">we</span>”, “<span className="font-bold">us</span>”, and “<span className="font-bold">our</span>”) and you (each of you and us being a “<span className="font-bold">Party</span>” and collectively, the “<span className="font-bold">Parties</span>”).</p>
          <br />
          <p>2.3. These Terms should be read in conjunction with our Privacy Statement (the “<span className="font-bold">Privacy Statement</span>”). In the event of any inconsistency between these Terms and any other pages, policies, terms, conditions, licenses, limitations, or obligations contained within or on the Site, these Terms shall prevail.</p>
          <br />

          <h2 className='font-bold italic'>3. We may make changes to these Terms</h2>
          <br />
          <p>3.1. We may modify or amend these Terms at any time in our sole discretion without prior notice to you. No consent of any Person is required for any modification or amendment to these Terms. All modifications or amendments are effective immediately when we post them on the Site.</p>
          <br />
          <p>3.2. Your continued use of the Site following the posting of modified or amended Terms means that you accept or agree to any modifications or amendments. You are expected to check this page each time you access the Site so you are aware of any changes, as they are binding on you. These Terms were last updated on June 18, 2024.</p>
          <br />
          <p>3.3. We recommend that you print a copy of these Terms for future reference.</p>
          <br />

          <h2 className='font-bold italic'>4. Who are we?</h2>
          <br />
          <p>4.1. The Site is a website operated by Holepunch, S.A. de C.V., an El Salvador Sociedad Anónima de Capital Variable with its registered office at Colonia Escalón, 87 Avenida Norte, Calle el Mirador, Edificio Torre Futura, Oficina 06, Nivel 11, San Salvador, El Salvador.</p>
          <br />
          <p>4.2. We may transfer our rights and any obligations under these Terms (or any part of these Terms) to another organisation, with or without notice to you.</p>
          <br />
          <p>4.3. The Site provides information regarding the protocol developed by us which enables peer-to-peer web development. Please note that the Site does not invite or induce any investment in digital assets, and we do not offer bitcoin, any other cryptocurrency, or any regulated investments through our Site. Any content on the Site is provided for information only.</p>
          <br />

          <h2 className='font-bold italic'>5. Who may use the Site?</h2>
          <br />
          <p>5.1. The Site and its content is not intended for use by or on behalf of: (i)  any Sanctioned Persons; (ii) any individual under the age of eighteen (18) years or who does not have the capacity to contract under these Terms under applicable laws; or (iii) any Person for which the access of the Site is prohibited by, would constitute a violation of, or would be subject to penalties under applicable laws (each a “<span className="font-bold">Prohibited Person</span>”). By continuing to access, view or make use of the Site, you hereby warrant and represent to us that you are not, and you are not accessing the Site on behalf of, a Prohibited Person. If you are, or are accessing the Site on behalf of, a Prohibited Person, you must immediately discontinue use of this Site.</p>
          <br />
          <p>5.2. On each day that you utilise the Site, you represent and warrant to us that you are not, and you are not accessing the Site on behalf of, a Prohibited Person.</p>
          <br />
          <p>5.3. If you are not, and are not accessing the Site on behalf of, a Prohibited Person, we grant you a personal, restricted, non-exclusive, non-transferable, non-sublicensable, revocable, limited license to use the Site, subject to the limitations and obligations set forth in these Terms.</p>
          <br />
          <p>5.4. For the purposes of these Terms:</p>
          <br />
          <p>5.4.1. <span className="font-bold">“Government”</span> means any national, federal, state, municipal, local, or foreign branch of government, including any department, agency, subdivision, bureau, commission, court, tribunal, arbitral body, or other governmental, government-appointed, or quasi-governmental authority or component exercising executive, legislative, juridical, regulatory, or administrative powers, authority, or functions of or pertaining to a government instrumentality, including any parasternal company, or state-owned (majority or greater) or controlled business enterprise;</p>
          <br />
          <p>5.4.2. <span className="font-bold">“Person”</span> includes an individual, association, partnership, corporation, company, other body corporate, trust, estate, and any form of organisation, group, or entity (whether or not having separate legal personality);</p>
          <br />
          <p>5.4.3. <span className="font-bold">“Sanctions List”</span> means the “Specially Designated Nationals and Blocked Persons” (“SDN”) List and the Non-SDN List, including the “Sectoral Sanctions Identifications List,” published by the Office of Foreign Assets Control of the U.S. Department of the Treasury; the Section 311 Special Measures for Jurisdictions, Financial Institutions, or International Transactions of Primary Money Laundering Concern published by the Financial Crimes Enforcement Network of the U.S. Department of the Treasury; or under sanctions, anti-money laundering or terrorist financing laws of or by Governments of the United States, the Republic of El Salvador or the British Virgin Islands (including any sanctioned, restricted, or debarred party list under the laws of the United Kingdom and applicable in the British Virgin Islands), as amended, supplemented, or substituted from time to time; and</p>
          <br />
          <p>5.4.4. <span className="font-bold">“Sanctioned Person”</span> refers to any Person that is: (i) specifically listed in any Sanctions List; (ii) directly or indirectly owned fifty per cent (50%) or more by any Person or group of Persons in the referred to in any Sanctions List; or (iii) subject to any authorisation, license, permit, consent, approval, franchise, concession, lease, ruling, certification, exemption, exception, filing or waiver by or with any Government necessary to conduct the business of either Party or any transaction entered into under these Terms, or otherwise sanctioned, restricted or penalised under applicable sanctions, anti-money laundering or terrorist financing laws</p>
          <br />

          <h2 className='font-bold italic'>6. We may make changes to, suspend or withdraw the Site</h2>
          <br />
          <p>6.1. We may make changes to the Site from time to time at our sole discretion to reflect changes to our services, our users’ needs, our business priorities, or otherwise. No consent of any Person is required for any changes to be made to the Site.</p>
          <br />
          <p>6.2. We do not guarantee that the Site, or any content on it, will always be available or be uninterrupted. We have the right to suspend, withdraw, or restrict your access to all or any part of the Site for any or no reason, including, without limitation, any violation of these Terms.</p>
          <br />

          <h2 className='font-bold italic'>7. How do you contact us?</h2>
          <br />
          <p>7.1. If you have any questions or concerns about these Terms or the use or content of our Site, please contact us at hello@holepunch.to.</p>
          <br />

          <h2 className='font-bold italic'>8. How may you use our Site?</h2>
          <br />
          <p>8.1. You may only use the Site for general informational purposes, on a non-reliance basis, in accordance with these Terms.</p>
          <br />

          <h2 className='font-bold italic'>9. How can you use the information on our Site?</h2>
          <br />
          <p>9.1. <span className='italic underline'>No Reliance</span>: All information provided to you on the Site is for general informational purposes only and should not be construed as professional advice. It is not intended to amount to advice or information on which you should rely. Any reliance you place on such information is strictly at your own risk. You should obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on our Site. We make no representations, warranties, covenants, or guarantees, whether express, implied, or statutory, that the content on our Site is accurate, complete or up-to-date.</p>
          <br />
          <p>9.2. <span className='italic underline'>Third-Party Information</span>: Where our Site contains links to other sites and resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them. We have no control over the contents of those sites or resources and accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third-party links, you do so entirely at your own risk and subject to the terms and conditions of use for such linked websites.</p>
          <br />

          <h2 className='font-bold italic'>10. Can you use our intellectual property?</h2>
          <br />
          <p>10.1. <span className='italic underline'>Intellectual Property</span>. The Site is protected by copyright, trademark, trade dress, trade secret, and other intellectual property or proprietary rights laws in various jurisdictions. All rights not expressly granted to you in these Terms are reserved by us and our licensor(s). Except as expressly authorised in writing by us, you will not (i) license, sublicense, rent, sell, resell, transfer, assign, distribute, or otherwise commercially exploit or make available to any Person all or any part of the Site in any way; (ii) copy, modify, republish, distribute, or make derivative works based upon all or any part of the Site; (iii) “frame” or “mirror” all or any part of the Site on any other server or wireless or internet-based device; or (iv) reverse-engineer or access all or any part of the Site in order to (a) build a competitive product or service, (b) build a product or service using similar ideas, features, functions, or graphics of all or any part of the Site, or (c) copy any ideas, features, functions, or graphics of all or any part of the Site.</p>
          <br />
          <p>10.2. <span className='italic underline'>Copyright Notice</span>: Unless otherwise indicated, we are the owner or licensee of all content or, material published on, the Site (“<span className="font-bold">Copyrights</span>”). You agree not to appropriate, copy, display, or use the Copyrights or other content without our express, prior, written permission. Our status (and that of any identified contributors) as the authors of content on our Site must always be acknowledged (except where the content is user-generated). You must not modify the paper or digital copies of any materials you have printed or downloaded in any way, and you must not use any illustrations, photographs, video, audio sequences or any graphics separately from any accompanying text. You may print or download extracts from our Site only for your personal use. You must not use any part of the content on our Site for commercial purposes without obtaining a license to do so from us or our licensors. If you print, copy, download, share or repost any part of our Site in breach of these terms of use, your right to use our Site will cease immediately, and you must, at our option, return or destroy any copies of the materials you have made.</p>
          <br />
          <p>10.3. <span className='italic underline'>Trademarks</span>: The trademarks, service marks, trade dress and trade names used on the Site, including both word marks and design marks (the “<span className="font-bold">Mark(s)</span>”), are owned or licensed by us. You agree not to appropriate, copy, display, reverse-engineer, or use the Marks or other content without our express, prior, written permission, including as a domain name, as a social media profile/handle, on a website, in an advertisement or other marketing, as or in connection with a phone number, as or in connection with an email address, in internet search results, in metadata or code, or in any other manner.</p>
          <br />
          <p>10.4. <span className='italic underline'>Prohibition on Registering or Using our Assets</span>. You must not register, record, or otherwise control any domains, social media handles/profiles, Marks or other trademark or service mark registrations, trade dress, trade names, doing business as designations or any other intellectual property rights featuring intellectual property owned by us or our licensor(s) directly or through a third party (“<span className="font-bold">Prohibited Assets</span>”). If we become aware that you own or control any Prohibited Asset(s), the Prohibited Asset(s) will be automatically transferred and assigned to us or our licensors under these Terms. You agree to execute all instruments and documents and do such additional acts as we or our licensor(s) may deem necessary or desirable to record and perfect the assignment of rights under this paragraph. If we or our licensor(s) are unable for any reason to secure your timely signature to any document it is entitled to under this paragraph within fourteen days, you hereby irrevocably designate and appoint us and our licensor(s) and our and their duly authorised directors, officers, and agents as your attorney-in-fact, with full power of substitution to act for and on your behalf and instead of you to execute and file any such document(s) and to do all other lawfully permitted acts to further the purposes of the foregoing with the same legal force and effect as if executed by you.</p>
          <br />
          <p>10.5. <span className='italic underline'>No Ownership</span>. Nothing in these Terms gives you any license (other than the limited right to access the Site on these Terms), right, title, or ownership of, in, the Site or any of its contents.</p>
          <br />
          <p>10.6. <span className='italic underline'>Web Scraping</span>: You shall not conduct, facilitate, authorise or permit any text or data mining or web scraping in relation to our Site, including using, or permitting, authorising or attempting the use of, any (i) “robot”, “bot”, “spider”, “scraper” or other automated device, program, tool, algorithm, code, process, or methodology to access, obtain, copy, monitor, or republish any portion of the Site or any data, content, information or services accessed via the same; or (ii) automated analytical technique aimed at analysing text and data in digital form to generate information which includes but is not limited to patterns, trends and correlations. This clause shall not apply insofar as (but only to the extent that) we are unable to exclude or limit text or data mining or web scraping activity by contract under the laws which are applicable to us.</p>
          <br />

          <h2 className='font-bold italic'>11. Can you link to our Site?</h2>
          <br />
          <p>11.1. You may link to the Site, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval, or endorsement on our part without our prior, express, written consent. We reserve the right to withdraw linking permission without notice at our discretion. You may not establish a link from any website that you do not own, cause the Site or portions of it to be displayed on or by any other website (for example, framing, deep linking, or in-line linking), or otherwise take any action with respect to the materials on the Site that is inconsistent with any other provision of these Terms.</p>
          <br />
          <p>11.2. The Site may provide certain social media features that enable you to link, send communications or display certain content from the Site. You may use these features solely as they are provided by us. </p>
          <br />
          <p>11.3. If you wish to link or make any use of the content on our Site other than as set out in these Terms, please contact us at hello@holepunch.to.</p>
          <br />

          <h2 className='font-bold italic'>12. Beta or Pre-release Testing</h2>
          <br />
          <p>12.1. Without limiting the other disclaimers of responsibility in these Terms, we may also provide access to features or services through the Site that are identified as “beta” or pre-release. Without limiting the preceding sentences in this paragraph, you understand that such services are still in development, may have bugs or errors, may be incomplete, may materially change prior to a full commercial launch, or may never be released commercially.</p>
          <br />

          <h2 className='font-bold italic'>13. How may we use your feedback?</h2>
          <br />
          <p>13.1. We invite you to provide, and may occasionally ask for, your feedback on the Site. By sharing feedback and suggestions with us, you grant us a worldwide, perpetual, irrevocable, non-exclusive, royalty-free license (with the right to sublicense) to use, copy, reproduce, modify, publish, transmit, broadcast, display, and distribute any intellectual property rights you may have in the feedback and suggestions you share, including to improve the Site. You agree that by submitting feedback or suggestions to us, you are not entitled to any form of compensation should we consider or develop technology that is the same or similar to such feedback or suggestions.</p>
          <br />
          <h2 className='font-bold italic'>14. How may we use your personal information?</h2>
          <br />
          <p>14.1. Please see our Privacy Statement for more information. These Terms should be read in conjunction with our Privacy Statement.</p>
          <br />

          <h2 className='font-bold italic'>15. Our liability for the Site</h2>
          <br />
          <p>15.1. <span className='underline italic'>Exclusion of Liability.</span> To the maximum extent permitted by applicable law, you irrevocably agree and acknowledge that neither us nor any of our direct and indirect subsidiaries, our holding companies, and other subsidiaries of those holding companies, and each of our and their shareholders, directors, officers, affiliates, employees, contractors, agents, partners, insurers, and attorneys (each an “<span className='font-bold'>Associate</span>”) assumes any liability or responsible for, or has any liability or responsibility for any claim, application, loss, injury, delay, accident, cost, business interruption costs, or any other expenses (including attorneys’ fees or the costs of any claim or suit), including any incidental, direct, indirect, general, special, punitive, exemplary, or consequential damages, loss of goodwill or business profits, work stoppage, data loss, computer failure or malfunction, or any and all other commercial losses (“<span className='font-bold'>Losses</span>”) directly or indirectly arising out of or related to the Site, including: (i) any information or materials available through the Site, whether originating from us or otherwise; (ii) any breach by you of these Terms or applicable law; (iii) any inaccurate, misleading, or incomplete statement by us on the Site, whether caused by our negligence or otherwise; (iv) any failure, delay, malfunction, interruption, or decision by us in operating the Site (including to cease operation of the Site or restrict its availability); or (v) any breach of security or data breach related to the Site, or any criminal or other third-party act affecting us or any of our Associates.</p>
          <br />
          <p>15.2. <span className='underline italic'>Release of Liability and Indemnity.</span> You hereby agree to release us and each Associate from liability for any and all such Losses, and you shall indemnify, save and hold the Associates harmless from and against all such Losses. To the maximum extent permitted by applicable law, the foregoing limitations of liability, releases, and indemnities shall apply whether the alleged liability or Losses are based on contract, negligence, tort, unjust enrichment, strict liability, violation of law or regulation, or any other basis, even if we or the applicable Associate(s) have been advised of or should have known of the possibility of such Losses and damages, and without regard to the success or effectiveness of any other remedies.</p>
          <br />
          <p>15.3. <span className='underline italic'>Disclaimer of Warranties.</span> Your use of the Site, its content, and any services or items to be obtained through the Site is at your own risk. The Site, its content, and any services or items obtained through the Site are provided on an “as is” and “as available” basis without any warranties of any kind, either express or implied. We do not make any warranties or representations with respect to the completeness, security, reliability, quality, accuracy, or availability of the Site. Without limiting the foregoing, we do not represent or warrant that the Site, its content, or any services or items obtained through the Site will be accurate, reliable, error-free, or uninterrupted, that defects will be corrected or that the Site or any services or items obtained through the Site will otherwise meet your needs or expectations. To the fullest extent provided by applicable law, we hereby disclaim all warranties of any kind, whether express or implied, statutory or otherwise, including, but not limited to, any warranties of merchantability and fitness for a particular purpose.</p>
          <br />
          <p>15.4. <span className='underline italic'>Viruses and Attacks:</span></p>
          <br />
          <p>15.4.1. We do not represent, warrant or guarantee that our Site or the server that makes it available will be secure or free from Viruses or other harmful components. You are responsible for configuring your information technology, computer programs and platform to access our Site. You should use your own Virus protection software. To the fullest extent permitted by applicable law, we will not be liable for any loss or damage caused by Viruses or other technologically harmful material that may infect your information technology, computer programs or platform to access our Site.</p>
          <br />
          <p>15.4.2. You must not introduce Viruses to our Site. You must not attack our Site via a denial-of-service attack or a distributed denial-of-service attack. By breaching this provision, you may commit a criminal offence, and we may report any such breach to the relevant law enforcement authorities. In the event of such a breach, your right to use our Site will cease immediately.</p>
          <br />
          <p>15.4.3. “<span className='font-bold'>Viruses</span>” means any harmful or surreptitious code with a purpose to, effect of, or that could be reasonably be expected to: (i) cause any unplanned interruption of the operation of a software or computer systems such as the Site, (ii) unauthorised use of a software, website or computer system such as the Site, (iii) altering, destroying, or inhibiting the use of a software, website or computer system such as the Site; or (iv) block access to, or prevent the use or accessibility of a software, website or computer system such as the Site. Viruses include malware, trojan horses, system monitors/keyloggers, dialers, adware, adware cookies lockup, time bombs, key lock device programs or disabling code.</p>
          <br />
          <p>15.5. <span className='underline italic'>No Trademark Liability.</span> If you elect to advertise “POWERED BY PEARS” or otherwise use the PEARS trademark in truthfully promoting your offerings that have a nexus with our PEARS offerings, you agree (a) not to seek any remedy, recourse, fees, damages, or any other claim against us, and (b) to fully indemnify us for any and all liabilities that we may incur as a result of your use, whether infringement, contributory, or otherwise.  Any claims brought against you, or brought as a result of your actions, may not be transferred to or claimed against us for any reason.  Any third party who believes its rights are infringed by your use of the term PEARS must seek its recourse solely against you and you agree to pay all amounts due, including official fees, attorneys’ fees, damages awards, treble damages, and all other costs, in the event a claim is brought against us that stems from your use of the PEARS brand.  If you do not agree to these terms, you may not use POWERED BY PEARS or PEARS in any manner at any time.  Use of PEARS will obligate you to these Terms, and the obligations in this paragraph 15(5).</p>
          
          <br />

          <h2 className='font-bold italic'>16. Governing law and other miscellaneous terms of the Site</h2>
          <br />
          <p>16.1. <span className='underline italic'>Governing law.</span> These Terms shall be governed by, and construed and enforced in accordance with, the laws of the British Virgin Islands, and shall be interpreted in all respects as a British Virgin Islands contract. Any dispute, controversy, claim or action arising from or related to your access or use of the Site or these Terms likewise shall be governed by the laws of the British Virgin Islands, exclusive of choice-of-law principles.</p>
          <br />
          <p>16.2. <span className='underline italic'>Jurisdiction.</span> We both agree that the courts of the British Virgin Islands will have exclusive jurisdiction. The foregoing shall be without prejudice to any applicable provisions of mandatory consumer protection laws under the laws of your country of residence, if and only to the extent that these offer you more protection.</p>
          <br />
          <p>16.3. <span className='underline italic'>Class Action Waiver.</span> Each Party expressly intends and agrees that: (a) class action and representative action procedures are hereby waived and will not be asserted, nor will they apply, in any dispute pursuant to these Terms; (b) neither Party will assert class action or representative action claims against the other in arbitration or otherwise; (c) each Party will only submit its own, individual claims in any dispute and will not seek to represent the interests of any other Person, or consolidate claims with any other person; (d) nothing in these Terms will be interpreted as either Party’s intent to adjudicate claims on a class or representative basis; and (e) any relief awarded to any one user of the Site cannot and may not affect any other user of the Site. No adjudicator may consolidate or join more than one Person’s or Party’s claims and may not otherwise preside over any form of a consolidated, representative, or class proceeding.</p>
          <br />
          <p>16.4. <span className='underline italic'>Jury Trial Waiver.</span> TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THE PARTIES HEREBY IRREVOCABLY AND UNCONDITIONALLY WAIVE ALL RIGHT TO TRIAL BY JURY IN ANY LEGAL ACTION OR PROCEEDING OF ANY KIND WHATSOEVER ARISING OUT OF OR RELATING TO THESE TERMS OR ANY BREACH THEREOF, ANY USE OR ATTEMPTED USE OF THE SITE BY YOU OR ANY OTHER MATTER INVOLVING THE PARTIES.</p>
          <br />
          <p>16.5. <span className='underline italic'>Headings:</span> The headings and sub-headings in these Terms are for ease of reference only and are not to be taken into account in the construction or interpretation of any provision or provisions to which they refer.</p>
          <br />
          <p>16.6. <span className='underline italic'>Extended Meanings:</span> Unless otherwise specified in these Terms, words importing the singular include the plural and vice versa, and words importing gender include all genders. The words “include”, “includes”, or “including” will be interpreted on an inclusive basis and be deemed to be followed by the words “without limitation”.</p>
          <br />
          <p>16.7. <span className='underline italic'>Assignment;</span> Third-Party Rights. These Terms and any of the rights, duties, and obligations contained or incorporated herein, are not assignable by you. Any attempt by you to assign these Terms without written consent is void. These Terms and any of the rights, duties, and obligations contained herein are freely assignable by us without notice or your consent (for clarity, this assignment right includes the right for us to assign any claim, in whole or in part, arising hereunder). Subject to the foregoing, these Terms, and any of the rights, duties, and obligations contained or incorporated herein, shall be binding upon and inure to the benefit of the heirs, executors, administrators, personal or legal representatives, successors and assigns of each Party. None of the provisions of these Terms, or any of the rights, duties, and obligations contained or incorporated herein, are for the benefit of or enforceable by any creditors of either Party or any other persons, except such as inure to a successor or assign in accordance herewith and as provided in the following sentence. Our Associates are intended third-party beneficiaries of the rights and privileges expressly stated to apply to Associates hereunder and shall be entitled to enforce such rights and privileges (including those rights and privileges set out in paragraph 15) as if in direct privity under these Terms, subject to the conditions and limitations hereof, including those relating to the resolution of disputes. No consent of any Person is required for any modification or amendment to these Terms.</p>
          <br />
          <p>16.8. <span className='underline italic'>Severability.</span> If any provision of these Terms or part thereof, as amended from time to time, is determined to be invalid, void, or unenforceable, in whole or in part, by any court of competent jurisdiction, such invalidity, voidness, or unenforceability attaches only to such provision to the extent of its illegality, unenforceability, invalidity, or voidness, as may be, and everything else in these Terms continues in full force and effect.</p>
          <br />
          <p>16.9. <span className='underline italic'>No Waivers; Available Remedies.</span> Any failure by us or any Associate to exercise any of its rights, powers, or remedies under these Terms, or any delay in doing so, does not constitute a waiver of any such right, power, or remedy. The single or partial exercise of any right, power, or remedy does not prevent any of them from exercising any other rights, powers, or remedies. The remedies under these Terms are cumulative with and not exclusive of any other remedy conferred by the provisions of these Terms, or by law or equity and may include (i) injunctions to prevent breaches of these Terms and to enforce specifically the terms and provisions hereof, and you waive the requirement of any posting of a bond in connection with such remedies; and (ii) the right to recover the amount of any Losses by set off against any amounts that we would otherwise be obligated to pay to you.</p>
        </div>
      </section>
    </Layout>
  )
}

export default Terms
